body {
  margin: 0;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: fixed;
  top: 0;
	left:0;
  bottom: 0;
	right: 0;
}

.cosmos {
  height: 100vh;
}

.stars {
  background: black url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/1231630/stars.png) repeat;
  position: absolute;
  top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: block;
  z-index: -2;
}

.twinkle {
    width: 10000px;
    height: 100%;
    background: transparent url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/1231630/twinkling.png) repeat;
    background-size: 1000px 1000px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    
    -moz-animation:move-background 40s linear infinite;
    -ms-animation:move-background 40s linear infinite;
    -o-animation:move-background 40s linear infinite;
    -webkit-animation:move-background 40s linear infinite;
    animation:move-background 40s linear infinite 
  }

  .twinkle::after {
    content: "";
    background: linear-gradient(0deg, rgb(54, 21, 0) 0%, rgb(0, 9, 32) 10%, rgba(14,0,25,1) 40%, rgba(4,0,11,1) 90%);
    opacity: .5;
    top: 0;     
    left: 0;
    bottom: 0;
    right: 0;
    position: fixed;
    z-index: -1;
  }


@keyframes move-background {
  from {
		-webkit-transform: translate3d(0px, 0px, 0px);
	}
	to { 
		-webkit-transform: translate3d(1000px, 0px, 0px);
	}
}
@-webkit-keyframes move-background {
  from {
		-webkit-transform: translate3d(0px, 0px, 0px);
	}
	to { 
		-webkit-transform: translate3d(1000px, 0px, 0px);
	}
}

@-moz-keyframes move-background {    
	from {
		-webkit-transform: translate3d(0px, 0px, 0px);
	}
	to { 
		-webkit-transform: translate3d(1000px, 0px, 0px);
	}
}

@-webkit-keyframes move-background {
	from {
		-webkit-transform: translate3d(0px, 0px, 0px);
	}
	to { 
		-webkit-transform: translate3d(1000px, 0px, 0px);
	}
}